<template lang="pug">
<div class="absolute bottom-5 top-5 left-5 ml-5 rounded-xl z-30 bg-white shadow grid grid-cols-2 pl-6 pt-5 grid-rows-layout w-module pb-4" style="left: 68px">
  <div class="text-darkblue col-start-1 col-end-2 text-xl font-bold whitespace-no-wrap font-SourceSansPro">{{ $t("menuSelect.notifications") }}</div>
  <div class="flex justify-end col-start-2 items-center">
    <img class="mr-5" src="../../assets/icons/history.svg" style="width: 18px; cursor: pointer" v-if="notRole || role === 'CONTROLLER'" @click="$emit('show-history', { objects: 'notifications' })" />
    <i class="el-icon-close text-2xl pr-4 cursor-pointer text-darkblue font-semibold" @click="$emit('close')"></i>
  </div>
  <div class="col-start-1 col-end-3 pb-3 pr-4 mt-6 border-b border-dividerColor flex justify-between">
    <skif-search :placeholder="$t('search')" class="w-full mr-4" v-model="filterString" @searching="searching"></skif-search>
    <div v-if="stateIcon === 'select'">
      <el-tooltip :open-delay="700" effect="dark" :content="$t('delete')" placement="bottom" :offset="2" :visible-arrow="false">
        <div class="focus_outline-none relative group" type="button" @click="deleteNotifications">
          <svg class="group-hover_fill-fillTrash transition-colors ease-in-out duration-200" width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill="#91B4E7">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 5H6v2h12V5h-3l-1-1h-4L9 5zm8 3H7v10a2 2 0 002 2h6a2 2 0 002-2V8z"></path>
          </svg>
          <skif-badge list="list" :value="notificationsToDelete.length"></skif-badge>
        </div>
      </el-tooltip>
    </div>
    <skif-button style="height: 35px" variant="normal" v-if="stateIcon === 'select'" @click="cancel">{{ $t("btn.cancel") }}</skif-button>
    <skif-button style="height: 35px" :disabled="disabledRole && role !== 'EDITOR' && role !=='OPERATOR'" v-if="stateIcon === 'normal'" @click="$emit('create-notify')">{{ $t("add") }}</skif-button>
  </div>
  <div class="col-start-1 col-end-3 pr-2 overflow-y-auto">
    <table class="w-full table-fixed">
      <thead class="text-left">
        <tr>
          <th class="w-12 bg-white top-0 sticky z-10">
            <skif-checkbox class="p-2" @click.native="(event) => event.stopPropagation()" v-model="isAllChecked" :indeterminate="isIndeterminate" @change="checkAllNotifies($event)" :disabled="!filterData.length"></skif-checkbox>
          </th>
          <th class="w-14 bg-white top-0 sticky"><span class="text-annotationColor text-sm font-SourceSansPro leading-10 font-semibold">{{ $t("notifications.view") }}</span></th>
          <th class="bg-white top-0 sticky"><span class="text-annotationColor text-sm font-SourceSansPro leading-10 font-semibold">{{ $t("notifications.name") }}</span></th>
          <th class="w-8 bg-white top-0 sticky"></th>
          <th class="w-10 bg-white top-0 sticky z-10"></th>
        </tr>
      </thead>
      <tbody is="transition-group" move-class="transition duration-1000">
        <tr class="cursor-pointer hover_bg-hoverrow" v-for="(notification, index) in filterData" @click="$emit('update-notify', notification)" :key="notification.id">
          <td>
            <skif-checkbox class="p-2" @change="changeChecked(notification, $event)" v-model="notification.isInArray" @click.native="(event) => event.stopPropagation()"></skif-checkbox>
          </td>
          <td><img :src="`/static/notification_icons/${notification.type.key}.svg`"/></td>
          <td class="truncate pr-2">
            <el-tooltip :open-delay="700" effect="dark" :content="notification.name" placement="bottom" :offset="0" :visible-arrow="false"><span class="font-bold leading-10 text-sm" :class="notification.isInArray ? 'text-headerText' : 'text-darkblue'">{{ notification.name }}</span></el-tooltip>
          </td>
          <td>
            <el-tooltip v-if="notification.sound && notification.sound.key !== 'no_sound'" :open-delay="700" effect="dark" :content="notification.sound.value" placement="bottom" :offset="0" :visible-arrow="false">
              <svg width="26" height="21" viewBox="0 0 26 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.1047 0.12213C16.9648 0.0421343 16.8063 0 16.6447 0C16.4833 0 16.3245 0.0421338 16.1847 0.12213L7.1967 4.54475H3.7375C3.0054 4.54475 2.30353 4.83297 1.78581 5.3459C1.26832 5.85904 0.977539 6.55474 0.977539 7.28039V12.7517C0.977539 13.4773 1.26832 14.173 1.78581 14.6862C2.30351 15.1991 3.0054 15.4873 3.7375 15.4873H7.1967L16.2031 19.9464C16.3312 20.0115 16.4731 20.0459 16.6171 20.0467C16.7894 20.0473 16.9582 19.9999 17.1046 19.9099C17.3742 19.743 17.5377 19.4499 17.5371 19.1348V0.897218C17.5377 0.582129 17.3742 0.28902 17.1046 0.122119L17.1047 0.12213ZM2.81756 12.7519V7.28065C2.81756 7.03884 2.91449 6.8068 3.08699 6.63582C3.25948 6.46485 3.49359 6.36877 3.73755 6.36877H6.49751V13.6638H3.73755C3.49359 13.6638 3.25948 13.5677 3.08699 13.3968C2.91449 13.2258 2.81756 12.9937 2.81756 12.7519ZM15.6974 17.6578L8.33748 14.0102V6.02215L15.6974 2.37463V17.6578Z" fill="#5477A9"></path>
                <path d="M23.7284 5.81201C23.5915 5.59157 23.3662 5.43932 23.1091 5.39311C22.852 5.34711 22.5871 5.41163 22.3809 5.5704C22.1745 5.72937 22.0462 5.96772 22.0277 6.22601C22.0092 6.48451 22.1024 6.73833 22.2842 6.92457C22.9778 7.80815 23.3547 8.8961 23.3547 10.0158C23.3547 11.1355 22.9778 12.2234 22.2842 13.107C22.1445 13.2869 22.0774 13.5119 22.0963 13.738C22.115 13.9643 22.2181 14.1754 22.3856 14.3303C22.553 14.485 22.7727 14.5723 23.0017 14.5752C23.2865 14.5745 23.5549 14.4433 23.7284 14.2196C24.6759 13.0195 25.1907 11.5395 25.1907 10.0157C25.1907 8.49201 24.6759 7.012 23.7284 5.81191L23.7284 5.81201Z" fill="#5477A9"></path>
                <path d="M19.8004 7.47214C19.6094 7.62114 19.4858 7.83914 19.4564 8.07852C19.4271 8.31768 19.4946 8.55868 19.6439 8.74878C19.9259 9.11211 20.0789 9.55785 20.0789 10.0162C20.0789 10.4746 19.9259 10.9204 19.6439 11.2837C19.4946 11.4738 19.4271 11.7148 19.4564 11.954C19.4858 12.1933 19.6094 12.4113 19.8004 12.5603C19.961 12.6839 20.1583 12.7513 20.3616 12.7519C20.6463 12.7513 20.9147 12.62 21.0884 12.3963C21.6256 11.7173 21.9174 10.8791 21.9174 10.0162C21.9174 9.15337 21.6256 8.31517 21.0884 7.63616C20.9393 7.44543 20.72 7.32127 20.4785 7.29054C20.2372 7.2596 19.9932 7.32494 19.8004 7.4721V7.47214Z" fill="#5477A9"></path>
              </svg>
            </el-tooltip>
          </td>
          <td>
            <skif-switch v-model="notification.is_active" @click.native="(event) => event.stopPropagation()" @change="changeActivity(notification.id, notification.is_active)"></skif-switch>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import cleanMapTool from '@/components/map/tools/cleanMapMenu';

export default {
  components: {
    // cleanMapTool,
  },
  data() {
    return {
      filterString: '',
      // defaultNotification: {},
      notificationsToDelete: [],
      stateIcon: 'normal',
      isAllChecked: false,
      isIndeterminate: false
    }
  },
  computed: {
    ...mapState('notifications', {
      notifications: (state) => state.notifications,
      recoveryId: (state) => state.recoveryId
    }),
    ...mapGetters('login', [
      'userEmail',
      'userId',
      'role',
      'disabledRole',
      'notRole'
    ]),

    notificationsIds() {
      return this.filterData.map((k) => k.id)
    },
    filterData() {
      return this.notifications.reduce(
        (result, notification) => {
          const isInArray = this.notificationsToDelete
            .map((k) => k.id)
            .includes(notification.id)
          return JSON.stringify(notification)
            .toLowerCase()
            .includes(this.filterString.toLowerCase())
            ? [...result, { ...notification, isInArray }]
            : result
        },

        []
      )
    }
  },
  watch: {
    // changingCompany() {
    //   console.log('11111change company notification!')
    //   this.filterString = ''
    //   this.cancel()
    // },

    filterData() {
      const valIds = this.notificationsToDelete.map((k) => k.id)
      this.isAllChecked =
        !!this.notificationsIds.length &&
        this.notificationsIds.every((id) => valIds.includes(id))
      this.isIndeterminate =
        !this.isAllChecked &&
        this.notificationsIds.some((id) => valIds.includes(id))
    }
  },
  methods: {
    searching(val) {
      this.filterString = val
    },
    closeDeletedNotification() {
      setTimeout(() => {
        this.$notify.closeAll()
      }, 10000)
    },
    changeChecked(notification, $event) {
      this.notificationsToDelete = $event.target.checked
        ? [...this.notificationsToDelete, notification]
        : this.notificationsToDelete.filter(
            (notify) => notify.id !== notification.id
          )
      const selectedLength = this.notificationsToDelete.length
      if (selectedLength === this.filterData.length) {
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else if (!selectedLength) {
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      } else {
        this.isAllChecked = false
        this.isIndeterminate = true
        this.stateIcon = 'select'
      }
    },
    checkAllNotifies($event) {
      // this.notificationsToDelete = $event.target.checked ? this.filterData : []
      if ($event.target.checked) {
        this.notificationsToDelete = this.filterData
        this.isAllChecked = true
        this.isIndeterminate = false
        this.stateIcon = 'select'
      } else {
        this.notificationsToDelete = []
        this.isAllChecked = false
        this.isIndeterminate = false
        this.stateIcon = 'normal'
      }
    },
    changeActivity(id, isActive) {
      // this.notifications[index].is_active = !this.notifications[index].is_active
      this.$store.dispatch('notifications/TOGGLE', {
        id,
        formData: { is_active: isActive }
      })
    },
    recoverItems(items) {
      const recoveryObject = {
        id: this.recoveryId,
        itemsDeleted: items
      }
      this.$store.dispatch(
        'notifications/RECOVER_NOTIFICATIONS',
        recoveryObject
      )
      this.$notify.closeAll()
    },
    deleteNotifications() {
      const someUnits = this.notifications.filter((el) =>
        this.notificationsToDelete.some((item) => el.id === item.id)
      )
      console.log(someUnits)
      this.$notify({
        dangerouslyUseHTMLString: true,
        message: `<div >
                      <span class="" > ${
                        this.notificationsToDelete.length <= 1
                          ? this.$t('one_element_deleted')
                          : this.notificationsToDelete.length +
                            this.$t('already_deleted_elements')
                      } </span>
                      <span id='confirm' class='cursor-pointer cancelConfirm text-annotationColor'>${this.$t(
                        'cancel'
                      )}</span>
                    </div>`,
        duration: 10000,
        position: 'bottom-left',
        customClass: 'confirmation'
      })
      this.closeDeletedNotification()
      const confirmation = document.getElementById('confirm')
      const self = this
      confirmation.addEventListener(
        'click',
        function () {
          self.recoverItems(someUnits)
        },
        false
      )
      this.$store
        .dispatch('notifications/DELETE', this.notificationsToDelete)
        .then((_) => {
          this.cancel()
        })
    },
    cancel() {
      this.stateIcon = 'normal'
      // this.$refs.notificationstable.clearSelection()
      this.notificationsToDelete = []
    },
    selectionItem(data) {
      this.stateIcon = data.length === 0 ? 'normal' : 'select'
      this.notificationsToDelete = data
    }
  }
}
</script>
<style lang="stylus">
.confirmation {
  width: 466px;
  background-color: #eff4fb;
  margin-left: 4.5rem;

  .el-notification__group {
    margin-right: 30px;
    width: 95%;

    .el-notification__content {
      width: 95%;

      p {
        .confirmation-container {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .el-icon-close {
      margin-top: 3px;

      &::before {
        font-weight: bolder;
        font-size: 18px;
      }
    }
  }
}
</style>
